import {websiteInitialData_cached_website} from '@data/queries/website/__generated__/websiteInitialData_cached'
import isStore from '@helpers/websites/isStore'

import useWebsiteBusinessType from './useWebsiteBusinessType'

export default function useIsStore(website?: websiteInitialData_cached_website) {
  let businessType = useWebsiteBusinessType()

  if (website?.businessType) {
    businessType = website.businessType
  }

  return isStore(businessType)
}
