import {useContext} from 'react'
import isServerSide from '@helpers/misc/isServerSide'
import WebsiteBusinessTypeContext from '@helpers/misc/WebsiteBusinessTypeContext'
import getDomain from '@helpers/websites/getDomain'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useWebsiteBusinessType() {
  const businessType = useContext(WebsiteBusinessTypeContext)

  const {website} = useQuery({
    query: gql`
      query getWebsiteBusinessTypeFromDomain_cached($domain: String) {
        website(domain: $domain) {
          _id
          businessType
        }
      }
    `,
    variables: {domain: getDomain()},
    fetchPolicy: 'cache-first',
    omit: isServerSide(),
    partial: true
  })

  if (businessType) return businessType

  if (!website || !getDomain()) return null

  return website.businessType
}
